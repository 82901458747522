<script setup lang="ts">
const { t } = useT();

const props = defineProps<{
	entries: number;
	finishedAt: string;
}>();

const { open } = useFunrizeModals();

const handleClick = () => {
	open("LazyOModalRacesGames");
};

const { durationLeft } = useCountdown({
	timestamp: convertDateTimeFormat(props.finishedAt),
	formatToken: "HH[h] mm[m]"
});
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 328,
			height: 257,
			src: '/nuxt-img/prize-drops/stars/promo.jpg',
			alt: 'prize-logo',
			format: 'avif'
		}"
		height="257px"
		@click="handleClick"
	>
		<template #default>
			<ABadge background="var(--carrara)" variant="skew" class="top-left" autosize>
				<AText variant="topeka" :modifiers="['bold', 'uppercase']">{{ t("Free to play") }}</AText>
			</ABadge>
		</template>
		<template #top>
			<div class="block">
				<AText as="div" variant="ternopil" :modifiers="['uppercase']" class="title">
					{{ t("Shooting Stars") }}
				</AText>
				<AText as="div" variant="topeka" :modifiers="['bold', 'uppercase']">
					{{ t("Every Day We Give away") }}
				</AText>
				<div v-if="entries !== 0" class="card-count entries">
					<MPrizeFund variant="entries" icon="20/entries" :icon-size="38">
						<AText variant="tanzay" class="text-cixi" :modifiers="['black']">
							{{ numberFormat(entries) }}
						</AText>
					</MPrizeFund>
				</div>
			</div>
		</template>
		<template #bottom>
			<AText variant="topeka" class="time">
				{{ t("Ends in") }} <b>{{ durationLeft }}</b>
			</AText>
			<AButton variant="primary" size="md" data-tid="promo-play-prizes">
				<AText variant="toledo" :modifiers="['bold', 'uppercase']">{{ t("Play for prizes") }}</AText>
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card-count {
	display: flex;
	align-items: center;
	gap: 4px;
}

button {
	width: 240px;
}

.top-left {
	position: absolute;
	top: 0;
	left: 0;
}

.card {
	&:deep(.top),
	&:deep(.bottom) {
		align-items: flex-start;
	}

	.time {
		margin-bottom: 24px;
	}

	.title {
		position: absolute;
		top: 42px;
		left: 20px;
		max-width: 100%;
	}
}

.icon-ticket {
	font-size: 24px;
	margin-left: 8px;
}
</style>
